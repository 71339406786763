import React from "react";
import SlideShow from '../components/slideshow/ProjectFiveSlide' 

function ProjectFive(){
  const backgroundImage = 'url(../assets/images/braemar-slide.jpg)';

  const style = {
      backgroundImage,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
  };

  return (
 <div>
 
    <div className="project-slider">
        <div className="third-wrapper">
            
            <div className="frame">
            <div id="projectSlideImg" style={style}>
                <div className="img-fade"></div>
                </div>
                <div className="wrapper">
                    <div className="project-slide-des">
                    <h1>Braemar</h1>
                    <p>Forest Hill</p>
                    </div>
                </div>
            </div>     
        </div> 
    </div>
 
  
        <section className="project">
            <div className="divider-project">
                <div className="project-des">
                    <h2>Project Details</h2>
                    <p>Lot: 2 lots each 35 x 130</p>
                    <p>Location: 32 & 34 Braemar Ave, Toronto.</p>
                    <p>Status: Planning</p>
                    <p>Architect: N/A</p>
                    <p>Interior: N/A</p>
                </div>

            </div>
        </section>
        
 
        <div className="project-leadership-img">
            <div className="img-construction">
                <img src="../assets/images/home-birds.png" alt="home construction"/>
            </div>   
        </div>
 

    <div className="wrapper">
        <section className="project-section">

                <SlideShow/>
          
        </section>
    </div>

    <div className="wrapper">
      <section className="project-des-section">

        <h3>Project Description</h3>
        <p>Description</p>

      </section>
    </div>



  
    </div>
  );
}

export default ProjectFive;