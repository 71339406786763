import React, { useState } from 'react';
import {NavLink} from "react-router-dom";
import logo from '../assets/images/jcg-logo-edited-old.png';
import MenuIcon from '../assets/images/menu-icon.png';
import CloseIcon from '../assets/images/close-icon.png';

const MobileMenu = () => {
 
  const [isMenuActive, setIsMenuActive] = useState(false);

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  const closeMenu = () => {
    setIsMenuActive(false);
  };

  return (
    <div>

      <nav className="mobile-nav">
          <div className="logo">
            <a href="/"><img src={logo} alt="jafari construction" /></a>
          </div>

              {/* Menu Icon */}
          <div className="menu-icon" onClick={toggleMenu}>
            <img src={MenuIcon} alt="menu" />
          </div>
      </nav>
 
      {/* Mobile Menu Container */}
      <div className={`mobile-menu-container ${isMenuActive ? 'active' : ''}`}>
        <div className="menu-close" onClick={closeMenu}>
            <img src={CloseIcon} alt="close" />
        </div>
      
          <div className="menu"> 
            <ul>
                <li><a className="smooth-scroll" href="/#about" onClick={closeMenu}>ABOUT&nbsp;US</a></li>
                <li><NavLink to="/blog" onClick={closeMenu}>BLOG</NavLink></li>
                <li><NavLink to="/projects" onClick={closeMenu}>PROJECTS</NavLink></li>
                <li><a className="smooth-scroll" href="#contact" onClick={closeMenu}>CONTACT&nbsp;US</a></li>
            </ul>
          </div>
    
      </div>
    </div>
  );
};

export default MobileMenu;
