 
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

import './App.css';

import Layout from './pages/Layout';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Projects from './pages/Projects';
import ProjectOne from './pages/ProjectOne';
import ProjectTwo from './pages/ProjectTwo';
import ProjectThree from './pages/ProjectThree';
import ProjectFour from './pages/ProjectFour';
import ProjectFive from './pages/ProjectFive';
import ProjectSix from './pages/ProjectSix';
import ProjectSeven from './pages/ProjectSeven';
// import Contact from "./pages/Contact";
// import NoPage from "./pages/NoPage";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/projects/project-1" element={<ProjectOne />} />
            <Route path="/projects/project-2" element={<ProjectTwo />} />
            <Route path="/projects/project-3" element={<ProjectThree />} />
            <Route path="/projects/project-4" element={<ProjectFour />} />
            <Route path="/projects/project-5" element={<ProjectFive />} />
            <Route path="/projects/project-6" element={<ProjectSix />} />
            <Route path="/projects/project-7" element={<ProjectSeven />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </Router>


  </div>
  );
}

export default App;
 