 
import React, { useState, useEffect } from 'react';
import upArrowImage from '../assets/images/up-arrow.png';
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button
          onClick={handleClick}
          style={styles.button}
        >
          <img src={upArrowImage} alt="Top" />
        </button>
      )}
    </>
  );
};

const styles = {
  button: {
    // position: 'fixed',
    // bottom: '40px',
    // right: '40px',
    // padding: '10px 20px',
    // fontSize: '16px',
    // backgroundColor: '#007BFF',
    // color: 'white',
    // border: 'none',
    // borderRadius: '5px',
    // cursor: 'pointer',
    // zIndex: 1000,
    position: 'fixed',
    bottom: '20px',
    right: '30px',
    zIndex: 99,
    border: 'none',
    outline: 'none',
    color: 'white',
    cursor: 'pointer',
    padding: '0',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
  },
};

export default ScrollToTopButton;
