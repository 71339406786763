import React, { useState } from 'react';
import './Slideshow.css'; 

const ProjectOneSlideshow = () => {
  const images = [
    '../assets/images/gallery/princess-01.jpg',
    '../assets/images/gallery/princess-02.jpg',
    '../assets/images/gallery/princess-03.jpg',
    '../assets/images/gallery/princess-04.jpg',
    '../assets/images/gallery/princess-05.jpg',
    '../assets/images/gallery/princess-06.jpg',
    '../assets/images/gallery/princess-07.jpg',
    '../assets/images/gallery/princess-08.jpg',
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const moveSlide = (n) => {
    let newIndex = currentSlide + n;
    if (newIndex < 0) {
      newIndex = images.length - 1;
    } else if (newIndex >= images.length) {
      newIndex = 0;
    }
    setCurrentSlide(newIndex);
  };

  const currentSlideHandler = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="slideshow-container">
      {images.map((img, index) => (
        <div
          className={`slides ${currentSlide === index ? 'active' : ''}`}
          key={index}
        >
          <img src={img} alt={`Slide ${index + 1}`} />
        </div>
      ))}
      <a className="prev" onClick={() => moveSlide(-1)}>&#10094;</a>
      <a className="next" onClick={() => moveSlide(1)}>&#10095;</a>
      <div className="navigator">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentSlide === index ? 'active' : ''}`}
            onClick={() => currentSlideHandler(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ProjectOneSlideshow;

