 
import React, { useEffect, useState } from 'react';
 

function Projects(){
    const [backgroundImage, setBackgroundImage] = useState('');

    useEffect(() => {
        const handleResize = () => {
            if (window.matchMedia("(max-width: 460px)").matches) {
                setBackgroundImage("url('assets/images/projects-mobile.jpg')");
            } else {
                setBackgroundImage("url('assets/images/projects.jpg')");
            }
        };

        // Set the initial background image on component mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

  return (
 <div>

        <div className="project-slider">
            <div className="third-wrapper">
                <div className="frame" style={{ backgroundImage: backgroundImage, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                    <div className="img-fade"></div>
                    <div className="wrapper">
                        <div className="project-slide-des">
                            <h1>Projects</h1>
                        </div>
                    </div>
                </div>     
            </div> 
        </div>
        

        <section className="projects">
            <div className="divider-projects">
                <div className="projects-items" id="project-1">
                    <a href="/projects/project-1"><img src="assets/images/241-princess.jpg" alt="241 Princess"/></a>
                    <div className="projects-des">
                        <h2>241 Princess</h2>
                        <p>Location: 241 princess Ave, Northyork. Status: Completed (Feb 2005 - Jan 2006).</p>
                    </div>
                </div>
              
                <div className="projects-items" id="project-2">
                    <a href="/projects/project-2"><img src="assets/images/31-hedgewood-rd-project.jpg" alt="31 Hedgewood"/></a>
                    <div className="projects-des">
                        <h2>31 Hedgewood</h2>
                        <p>Location: 31 Hedgewood Rd, North York, M2L 1L6. Status: Completed (Sep 2021 - Mar 2023)</p>
                    </div>
                </div>
               
                <div className="projects-items" id="project-3">
                    <a href="/projects/project-3"><img src="assets/images/66-chatsworth.jpg" alt="66 Chatsworth"/></a>
                    <div className="projects-des">
                        <h2>66 Chatsworth</h2>
                        <p>Location: 66 Chatsworth Dr, Toronto, M4R 1R7. Status: Completed (Nov 2020 - Sep 2021)</p>
                    </div>
                </div>
            
                    <div className="projects-items" id="project-4">
                        <a href="/projects/project-4"><img src="assets/images/213-rosemary.jpg" alt="213 Rosemary"/></a>
                        <div className="projects-des">
                            <h2>213 Rosemary</h2>
                            <p>Location: 13 Rosemary Rd, Toronto, M5P 3E2. Status: Permit.</p>
                        </div>
                    </div>
                  
                    <div className="projects-items" id="project-5">
                        <a href="/projects/project-5"><img src="assets/images/braemar.jpg" alt="Braemar"/></a>
                        <div className="projects-des">
                            <h2>Braemar</h2>
                            <p>Location: 32 & 34 Braemar Ave, Toronto. Status: Planning.</p>
                        </div>
                    </div>
                   
                    <div className="projects-items" id="project-6">
                        <a href="/projects/project-6"><img src="assets/images/361-kingsdale-project.jpg" alt="361 Kingsdale"/></a>
                        <div className="projects-des">
                            <h2>361 Kingsdale</h2>
                            <p>Location: 361 Kingsdale Ave, North York. Status: Completed (Aug 2003 - May 2004)</p>
                        </div>
                    </div>

                    <div className="projects-items" id="project-7">
                        <a href="/projects/project-7"><img src="assets/images/wilmington.jpg" alt="Wilmington"/></a>
                        <div className="projects-des">
                            <h2>Wilmington</h2>
                            <p>Location: 25 - 41 Wilmington Ave, Toronto. Status: Completed (Mar 2018 - Sep 2020)</p>
                        </div>
                    </div>
            </div>
        </section>
        
 
        <div className="projects-leadership-img">
            <div className="img-construction">
                <img src="assets/images/home-birds.png" alt="home construction"/>
            </div>   
        </div>
   
 

    <div className="wrapper">
        <section className="project-section">
 
    
        </section>
    </div>



  
    </div>
  );
}

export default Projects;