
import React from 'react';
 
import slid from '../assets/images/slide-2.jpg'; 
import slidMobile from '../assets/images/slide-mobile.jpg'; 
 
import ServiceItems from '../components/ServiceItems';

function Home() {
  return (
    <div>

<div className="slider">
      <img className="slide-img" src={slid} alt="construction" />
      <div className="wrapper">
        <div className="frame">
          <div className="slide-des">
            <h1>UNLEASHING DREAMS<br /> &nbsp;&nbsp; BEYOND BLUE PRINTS</h1>
          </div>
        </div>
      </div>      
    </div>

  <div className="slider-mobile">
      <img className="slide-mobile-img" src={slidMobile} alt="construction" />
      <div className="wrapper">
        <div className="frame">
          <div className="slide-mobile-des">
            <h1>UNLEASHING DREAMS<br /> &nbsp;&nbsp; BEYOND BLUE PRINTS</h1>
          </div>
        </div>
      </div>      
  </div>
          {/* <div class="img-fade"></div> */}
    {/* <Element name="about"> */}
      <div className="second-wrapper">
        <div id="about" className="about-des">
          <h2>WE BUILD A UNIQUE WORLD</h2>
          <p>Jafari Construction Group(JCG) is a trusted real estate development company with over 2 decades of experience building homes and communities in Toronto. With a rich legacy spanning more than four decades, our experience extends globally. Committed to quality and innovation, we bring a wealth of expertise to every endeavor.</p>
        </div>
      </div>
    {/* </Element> */}

    <ServiceItems />
   
    <div className="second-wrapper leadership">
      <div className="divider-leadership">
        <div className="text-des">
          <h2>OUR LEADERSHIP CHRONICLE</h2>
          <p>Jafari Construction Group(JCG) is a trusted real estate development company with over 2 decades of experience building homes and communities in Toronto. With a rich legacy spanning more than four decades, our experience extends globally. Committed to quality and innovation, we bring a wealth of expertise to every endeavor.</p>
        </div>
      </div>
    </div>
    <div className="leadership-img">
      <div className="img-construction">
        <img src="../assets/images/home-birds.png" alt="home construction" />
      </div>   
    </div>
    <div className="wrapper">
      <section className="blog">
        <h2>Blog</h2>
        <div className="blog-section">
          <div className="blog-items">
            <a className="smooth-scroll" href="/blog#blog-1"><img src="./assets/images/blog-thumb-1.jpg" alt="article 1" /></a>
            <div className="blog-date">
              <img src="./assets/images/calendar-icon.png" alt="calendar" /> <span>Feb 21, 2024</span>
            </div>
            <p>We had committee of adjustment meeting for our Rosemary project and [...]</p>
          </div>
          <div className="blog-items">
            <a className="smooth-scroll" href="/blog#blog-2"><img src="./assets/images/blog-thumb-2.jpg" alt="article 2" /></a>
            <div className="blog-date">
              <img src="./assets/images/calendar-icon.png" alt="calendar" /> <span>Jul 1, 2024</span>
            </div>
            <p>We acquired two properties with the intention of developing them as part [...]</p>
          </div>
          <div className="blog-items">
            <a className="smooth-scroll" href="/blog#blog-3"><img src="./assets/images/blog-3.png" alt="article 3" /></a>
            <div className="blog-date">
              <img src="./assets/images/calendar-icon.png" alt="calendar" /> <span>May 24, 2024</span>
            </div>
            <p>The Angus Glen South Elementary School project is part of a $1.3 billion [...]</p>
          </div>
        </div>
      </section>
    </div>

    </div>
  );
}

export default Home;
