import React from "react";
 
import blog1 from '../assets/images/blog-thumb-1.jpg'; 

function Blog(){
  const backgroundImage = 'url(../assets/images/blog.jpg)';

  const style = {
      backgroundImage,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
  };

  return (
 <div>

        <div className="blog-slider">
          <div className="third-wrapper">
              <div className="frame">
                  <div id="projectSlideImg" style={style}>
                      <div className="img-fade"></div>
                  </div>
                  <div className="wrapper">
                      <div className="blog-slide-des">
                          <h1>BLOG</h1>
                      </div>
                  </div>
            </div>
        </div>
        </div>
 
 

        <section className="blogs">
            <div className="divider-blogs">
                <div className="blogs-items" id="blog-1">
                    <img src={blog1} alt="blog"/>
                    <div className="blogs-des">
                        <div className="blogs-date">
                            <img src="assets/images/calendar-icon.png" alt="calendar"/> <span>Feb 21, 2024</span>
                        </div>
                        <h2>Successful Negotiations and Approval at Committee of Adjustment Meeting for Rosemary Project</h2>
                        <p>We had committee of adjustment meeting for our Rosemary project and received approval for all our requested variances. Prior to the meeting, We engaged in challenging negotiations with our neighbors to reach an agreement that satisfied everyone while achieving the best outcome.</p>
                    </div>                   
                </div>
                
                <div className="blogs-items" id="blog-2">
                    <img src="assets/images/blog-thumb-2.jpg" alt="blog"/>
                    <div className="blogs-des">
                        <div className="blogs-date">
                            <img src="assets/images/calendar-icon.png" alt="calendar"/> <span>Jul 1, 2024</span>
                        </div>
                        <h2>Expanding Affordable Housing Options in Toronto: Acquiring and Developing Properties for Alleviating the Housing Shortage</h2>
                        <p>We acquired two properties with the intention of developing them as part of the City of Toronto's initiative to expand affordable housing options. Our goal was to contribute to the ongoing effort to alleviate the housing shortage in the city by creating accessible and affordable living spaces.</p>
                    </div>
                </div>
               
                <div className="blogs-items" id="blog-3">
                    <img src="assets/images/blog-3.png" alt="blog"/>
                    <div className="blogs-des">
                        <div className="blogs-date">
                            <img src="assets/images/calendar-icon.png" alt="calendar"/> <span>May 24, 2024</span>
                        </div>
                        <h2>Ontario commits $30 million for new Angus Glen South elementary school in Markham</h2>
                        <p>The Angus Glen South Elementary School project is part of a $1.3 billion investment to build new schools and expansions, including child care spaces, announced by Stephen Lecce, Minister of Education.</p>
                        <p>However, Joe Tigani, educational assistant and president of CUPE’s Ontario School Boards Council of Unions (OSBCU), criticized the government’s new funding model, saying it doesn’t address current staffing shortfalls and fails to invest in more front-line staff to improve the quality of education.</p>
                    </div>
                </div>
            </div>
        </section>
        
 
        <div className="blogs-leadership-img">
            <div className="img-construction">
                <img src="assets/images/home-birds.png" alt="home construction"/>
            </div>   
        </div>
 

    <div className="wrapper">
 
    </div>



  
    </div>
  );
}

export default Blog;