import React from "react";
import SlideShow from '../components/slideshow/ProjectThreeSlide' 

function ProjectThree(){
  const backgroundImage = 'url(../assets/images/66-chatsworth-slide.jpg)';

  const style = {
      backgroundImage,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
  };

  return (
 <div>
 
    <div className="project-slider">
        <div className="third-wrapper">
            
            <div className="frame">
            <div id="projectSlideImg" style={style}>
                <div className="img-fade"></div>
                </div>
                <div className="wrapper">
                    <div className="project-slide-des">
                        <h1>66 Chatsworth</h1>
                        <p>Lawrence Park</p>
                    </div>
                </div>
            </div>     
        </div> 
    </div>
 
  
        <section className="project">
            <div className="divider-project">
                <div className="project-des">
                  <h2>Project Details</h2>
                  <p>Lot: 40' x 120'</p>
                  <p>Total SQF: 3,600 Sqf</p>
                  <p>Location: 66 Chatsworth Dr, Toronto, M4R 1R7.</p>
                  <p>Status: Completed (Nov 2020 - Sep 2021)</p>
                  <p>Architect: Urban Scape Architects</p>
                  <p>Interior: Urban Scape Architects & JCG Homes</p>
                </div>

            </div>
        </section>
        
 
        <div className="project-leadership-img">
            <div className="img-construction">
                <img src="../assets/images/home-birds.png" alt="home construction"/>
            </div>   
        </div>
 

    <div className="wrapper">
        <section className="project-section">

                <SlideShow/>
          
        </section>
    </div>

    <div className="wrapper">
      <section className="project-des-section">

        <h3>Project Description</h3>
        <p>Description</p>

      </section>
    </div>



  
    </div>
  );
}

export default ProjectThree;