import React from 'react';
import { Outlet } from 'react-router-dom';
 

import ScrollToTopButton from '../components/ScrollToTopButton';
import NavBar from '../components/Navbar';
import MobileMenu from '../components/MobileMenu';

import logoFooter from '../assets/images/home.png';
import FaceBook from '../assets/images/facebook.png';
import Linkedin from '../assets/images/linkedin.png';
import Instagram from '../assets/images/instagram.png';
import logoFooterGray from '../assets/images/home-gray.png';

function Layout() {
 
  return (
    <div>
<div id="home" />
    <header>
      <div className="wrapper">
    
        <NavBar/>
  
 

        <MobileMenu/>
        {/* <div className="mobile-menu-container">
          <div className="menu-close">
            <img src="./assets/images/close-icon.png" alt="close" />
          </div>
          <div className="menu"> 
            <ul>
                <li><a className="smooth-scroll" href="/#about">ABOUT&nbsp;US</a></li>
                <li><NavLink to="/blog">BLOG</NavLink></li>
                <li><NavLink to="/projects">PROJECTS</NavLink></li>
                <li><a className="smooth-scroll" href="#contact">CONTACT&nbsp;US</a></li>
            </ul>
          </div>
        </div> */}

        {/* End of Mobile Navigation Menu */}
      </div>
    </header>

 
       
        <Outlet />
     

      <footer className="footer">
      <div id="contact" className="footer-logo">
        <div><img src={logoFooter} alt="jafari construction logo" /></div>
      </div>
      <div className="vertical-divider-left" />
      <div className="footer-contact">
        <div className="contact-item">
          <h4>CONTACT</h4>
          <p>31 Hedgewood rd, North York, ON, M2L 1L6</p>
          <p>Tel: <span>647-309-8939</span></p>
          <p>Email: <span>info@JCGHomes.ca</span></p>
        </div>
        <div className="customer">
          <h4>CUSTOMER CARE</h4>
          <p>Tel: <span>647-309-8939</span></p>
          <p>Email: <span>cc@JCGHomes.ca</span></p>
        </div>
      </div>
      <div className="vertical-divider-right" />
      <div className="footer-social">
        <div><a href="/"><img src={FaceBook} alt="facebook" /></a></div>
        <div><a href="/"><img src={Linkedin} alt="linkedin" /></a></div>
        <div><a href="https://www.instagram.com/jcg.homes"><img src={Instagram} alt="instagram" /></a></div>
      </div>
    </footer>
    <div className="footer-social-logo">
      <img src={logoFooterGray} alt="jafari construction logo" />
    </div>
    <div className="signature">Designed by <a target="_blank" rel="noreferrer noopener" href="https://farsimeeting.com">FarsiMeeting.com</a></div>
    <button id="btnTop" title="Top"><a href="#home" className="smooth-scroll"><img src="./assets/images/up-arrow.png" alt="Top" /></a></button> 
    <ScrollToTopButton />
    </div>
  );
}

export default Layout;